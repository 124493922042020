import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyBxYXXeOyrl_JFpCpwgj5wsQwYrxyVm3M0",
  authDomain: "react-base-app-8d204.firebaseapp.com",
  projectId: "react-base-app-8d204",
  storageBucket: "react-base-app-8d204.appspot.com",
  messagingSenderId: "212943247121",
  appId: "1:212943247121:web:c22a88e455674e5bc5deb0"
};

const app = firebase.initializeApp(firebaseConfig);
const auth = app.auth();
const db = app.firestore();

export {
  auth,
  db
};